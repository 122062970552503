import React from 'react';
import ResponsiveSlider from '../responsive-slider/responsive-slider';
import styles from './trustaff-traveler-slider.module.scss';

export default () => {
  const slides = [
    {
      title: 'Resources for mental health and wellness',
      description:
        'Taking care of yourself is just as important as taking care of your patients.',
      ctaButtonText: 'Learn more',
      ctaButtonLink: '/trustaff-traveler/mental-health/',
      iconClass: styles.slideIconCaring,
    },
    {
      title: 'Exclusive employee discounts',
      description:
        'In addition to great pay and service, all our travelers are eligible for perks & discounts just for being a part of the family!',
      ctaButtonText: 'Learn more',
      ctaButtonLink: '/perks-discounts/',
      iconClass: styles.slideIconPerks,
    },
    {
      title: 'Explore the ACT Program',
      description:
        'As a Trustaff traveler, you qualify for additional career tools, professional advocacy, and wellness resources through the Ingenovis Health ACT Program',
      ctaButtonText: 'Learn more',
      ctaButtonLink: '/ACTprogram/',
      iconClass: styles.slideIconBenefits,
    },
  ];

  return <ResponsiveSlider slides={slides} />;
};
