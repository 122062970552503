import React from 'react';
import Img from 'gatsby-image';
import styles from './trustaff-traveler-about-section.module.scss';
import facebookIconPath from '../../images/shared/social/facebook.png';
import linkedInIconPath from '../../images/shared/social/linkedin.png';
import twitterIconPath from '../../images/shared/social/twitter.png';
import instagramIconPath from '../../images/shared/social/instagram.png';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            So you’re setting out on a new adventure, now what?
          </h3>
          <div className="content">
            <p className="content">
              Welcome to the Travelers’ Hub, the perfect place for you to keep
              in touch from the road. Connecting with other travelers is one of
              the most rewarding parts of the travel experience.
            </p>
            <p className="content">
              Social media is a great way to foster that feeling of belonging
              while you're traveling. Join our thriving Facebook community,
              share photos of your journey on Instagram, or jump in the
              conversation on twitter.
            </p>

            <div className={styles.socialLinks}>
              <a
                className={`${styles.socialLink}`}
                href="https://www.facebook.com/trustaffHealthcareCareers/"
              >
                <span className="is-sr-only">Trustaff Facebook</span>
                <img src={facebookIconPath} alt="Trustaff facebook" />
              </a>
              <a
                className={`${styles.socialLink}`}
                href="http://www.linkedin.com/company/Trustaff"
              >
                <span className="is-sr-only">Trustaff LinkedIn</span>
                <img src={linkedInIconPath} alt="Trustaff LinkedIn" />
              </a>
              <a
                className={`${styles.socialLink}`}
                href="https://twitter.com/Trustaff"
              >
                <span className="is-sr-only">Trustaff twitter</span>
                <img src={twitterIconPath} alt="Trustaff twitter" />
              </a>
              <a
                className={`${styles.socialLink}`}
                href="https://www.instagram.com/trustafftravel/"
              >
                <span className="is-sr-only">Trustaff Instagram</span>
                <img src={instagramIconPath} alt="Trustaff Instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className={`column is-5 is-offset-1 is-hidden-mobile`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="Trustaff traveler community"
          />
        </div>
      </div>
    </div>
  );
};
