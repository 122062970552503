import React from 'react';
import Img from 'gatsby-image';
import styles from './trustaff-traveler-bullets-section.module.scss';

export default ({ topFluid, bottomFluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Join our Quarterly Photo Contest
          </h3>
          <div className="content">
            <p className={`content`}>
              We love sharing in your travel journey. Subjects can be anything
              from the beautiful sunset over the ocean to the unique details
              discovered on your daily walk or your favorite furry friend. If
              you did it, we want to see it!
            </p>
            <p className={`content`}>
              Winners are announced at the end of each quarter and receive a{' '}
              <b>$100 gift card!</b>
            </p>
            <p className={`content ${styles.subheader}`}>
              Congratulations to our 2nd quarter winner, Alysha N.!
            </p>
            <p className="content">
              Alysha took this beautiful photo while traveling with her fellow
              #TrustaffTraveler, Shane. They visited the Pagosa Springs for a
              relaxing day off in Colorado. The resort has 25 individual springs
              all varying in temperature. These springs are the deepest hot
              springs in the world! What an amazing way to spend an off day with
              your travel buddy and congratulations on snagging this amazing
              photo as well.
            </p>
          </div>
          <hr />
          <h3 className="title is-5 has-text-info">How to enter</h3>
          <div className="content">
            <p className={`content`}>
              Join our community of fellow travelers by following{' '}
              <a href="https://www.instagram.com/trustafftravel/">
                @trustafftravel
              </a>{' '}
              on Instagram and then share your photos by tagging them with{' '}
              <a href="https://www.instagram.com/explore/tags/trustafftraveler">
                #TrustaffTraveler
              </a>
              . It's our favorite way to see your adventures. (
              <em>Important tip:</em> If your Instagram profile is private, be
              sure to add @trustafftravel as a friend!)
            </p>
            <p className={`content`}>
              You can also send your photos <b>directly to your recruiter</b>.
              Make sure you tell us where you are and what you were doing when
              the photo was taken!
            </p>
            <p className={`content ${styles.legal}`}>
              Please note: By submitting a photo to the Trustaff Quarterly Photo
              Contest, you grant permission for Trustaff to reproduce any
              portion of the photo submitted that was taken by you for the
              purpose of internal and external general promotion which can
              include but is not limited to: social media reposts, banners,
              slides, advertisements, and appearing on this website. Trustaff
              will never resell your photo or release your photo in any manner
              that would exploit or cause malicious representation, and does not
              claim creative ownership of your photo.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={topFluid}
            className={`${styles.aboutImageWrapper} ${styles.img1}`}
            alt="Trustaff traveler photo contest winner"
          />
          <Img
            fluid={bottomFluid}
            className={`${styles.aboutImageWrapper} ${styles.img2}`}
            alt="Trustaff traveler photo contest winner 2"
          />
        </div>
      </div>
    </div>
  );
};
