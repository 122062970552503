import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './index.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import TrustaffTravelerSlider from '../../components/trustaff-traveler/trustaff-traveler-slider';
import TrustaffTravelerAboutSection from '../../components/trustaff-traveler/trustaff-traveler-about-section';
import TrustaffTravelerBulletsSection from '../../components/trustaff-traveler/trustaff-traveler-bullets-section';
import TrustaffTravelerClosingSection from '../../components/trustaff-traveler/trustaff-traveler-closing-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getTrustaffTravelerImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath = getTrustaffTravelerImageEdge('trustaff-traveler-og')
    .node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Welcome to the #TrustaffTraveler family - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Stay up to date with important news from home base, connect with your peers in our Trustaff traveler community, and share your experiences (and photos!) with fellow healthcare professionals."
        />
        <meta
          property="og:title"
          content="Welcome to the #TrustaffTraveler family"
        />
        <meta
          property="og:description"
          content="Stay up to date with important news from home base, connect with your peers in our Trustaff traveler community, and share your experiences (and photos!) with fellow healthcare professionals."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/trustaff-traveler/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getTrustaffTravelerImageEdge('trustaff-traveler').node
              .childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-6 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Welcome to the #TrustaffTraveler family.
                </h1>
                <p className={`content`}>
                  Life on the road presents its own unique adventures and
                  challenges. Who better to share it with than other people who
                  know exactly what you’re going through? Your Trustaff support
                  team is always here to listen, and we love sharing in your
                  journey.
                </p>
                <p className={`content`}>
                  Stay up to date with important news from home base, connect
                  with your peers in our travel nursing community, and share
                  your experiences (and photos!) with fellow healthcare
                  professionals.
                </p>
              </div>
            </div>
            <div
              className={`column is-5 is-offset-1 is-hidden-mobile ${styles.icon}`}
            ></div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <TrustaffTravelerAboutSection
            fluid={
              getTrustaffTravelerImageEdge('trustaff-traveler-pets').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionBackgroundGrey}`}>
          <TrustaffTravelerSlider />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <TrustaffTravelerBulletsSection
            topFluid={
              getTrustaffTravelerImageEdge('photo-contest-winner').node
                .childImageSharp.fluid
            }
            bottomFluid={
              getTrustaffTravelerImageEdge('photo-contest-runnerup').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <TrustaffTravelerClosingSection
            fluid={
              getTrustaffTravelerImageEdge('trustaff-traveler-store').node
                .childImageSharp.fluid
            }
          />
        </div>
        <HeroImageMini
          fluid={
            getTrustaffTravelerImageEdge('trustaff-team-collage').node
              .childImageSharp.fluid
          }
        ></HeroImageMini>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^trustaff-traveler/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
