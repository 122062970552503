import React from 'react';
import Img from 'gatsby-image';
import styles from './trustaff-traveler-closing-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Proud to be a #TrustaffTraveler
          </h3>
          <div className="content">
            <p className={`content`}>
              There's no better way to show off your pride than by sporting some
              Trustaff apparel! Visit our online{' '}
              <a
                href="http://trugearstore.com/"
                target="_blank"
                rel="noreferrer"
              >
                TruGear Store
              </a>{' '}
              to browse apparel and accessories.
            </p>
            <p className="content">
              Free shipping on all orders and items rotate frequently.
            </p>
          </div>
          <a
            href="http://trugearstore.com/"
            className="button is-info"
            target="_blank"
            rel="noreferrer"
          >
            Shop now
          </a>
        </div>
        <div className={`column is-5 is-offset-1 is-hidden-mobile`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="Trustaff traveler gear store"
          />
        </div>
      </div>
    </div>
  );
};
